





















import {Vue,Component} from 'vue-property-decorator'

@Component({
    name : 'NotFound',
})
export default class NotFound extends Vue  
{

}
